import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Link to="/">
          <div className="banner">
            <img
              src="./img/home/banner.jpg"
              alt="RJ Chavez Photography"
            />
          </div>
        </Link>
        <div className="contact">
          <span className="email">
            <a href="mailto:Rogerjchavez77@gmail.com">
              ROGERJCHAVEZ77@GMAIL.COM
            </a>
          </span>
          <span> | </span>
          <span className="phone-top">
            <a href="tel:630-779-8699">
              630.779.8699
            </a>
          </span>
        </div>
      </div>
    )
  }
}

export default Header;
