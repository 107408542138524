import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import Portraits from './components/Portraits';
import Posters from './components/Posters';
import Events from './components/Events';
import RealEstate from './components/RealEstate';
import Concerts from './components/Concerts';
import Video from './components/Video';

function App() {
  return(
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/portraits' component={Portraits} />
        <Route exact path='/movieposters' component={Posters} />
        <Route exact path='/events' component={Events} />
        <Route exact path='/realestate' component={RealEstate} />
        <Route exact path='/concerts' component={Concerts} />
        <Route exact path='/video' component={Video} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
