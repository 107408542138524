import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Header from './Header';
import Footer from './Footer';

class RealEstate extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
          <div className="view">
            <Carousel>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/1.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/2.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/3.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/4.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/5.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/6.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/7.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/8.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/9.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/10.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/11.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/12.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/13.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/14.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/15.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/16.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/17.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/18.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/19.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/20.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/21.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/22.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/23.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/24.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/25.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/26.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/27.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/real-estate/28.jpg"
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
          </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default RealEstate;
