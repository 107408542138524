import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Header from './Header';
import Footer from './Footer';

class Concerts extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
          <div className="view">
            <Carousel>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/1.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/2.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/3.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/4.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/5.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/6.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/7.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/8.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/9.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/10.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/11.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/12.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/13.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/14.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/15.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/16.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/17.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/18.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/19.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/20.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/21.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/22.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/23.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/24.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/25.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/26.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/27.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/28.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/29.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/30.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/31.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/32.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/33.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/34.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/35.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/36.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/37.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/38.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/39.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/40.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/41.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/42.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/43.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/44.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/45.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/46.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/47.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/48.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/49.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/50.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/51.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/52.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/53.jpg"
                  alt=""
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="img-fluid rounded mx-auto d-block"
                  src="../img/concerts/54.jpg"
                  alt=""
                />
              </Carousel.Item>
            </Carousel>
          </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Concerts;
