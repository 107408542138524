import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';

class Video extends Component {
  render() {
    return (
      <React.Fragment>
      <Header />
          <div className="video-container">
            <div className="embed">
              <iframe
                src="https://www.youtube.com/embed/gD68pHMV8tA"
                title="TAKE THE REINS - 'Camo Jacket'"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media;  gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </div>
            <div className="embed">
              <iframe
                src="https://www.youtube.com/embed/4hJA1u8jq88"
                title="My Blue Couch"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </div>
            <div className="embed">
              <iframe
                src="https://www.youtube.com/embed/UPQxqd11SXo"
                title="coming soon..."
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </div>

          </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Video;
