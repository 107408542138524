import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

import Header from "./Header";
import Footer from "./Footer";

class Portraits extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="view">
          <Carousel>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/1.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/2.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/3.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block  rounded mx-auto d-block"
                src="../img/portraits/4.jpg"
                alt=""
              />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/5.jpg"
                alt=""
              />
            </Carousel.Item> */}
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/6.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/7.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/8.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/9.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/10.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/11.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/12.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/13.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/14.jpg"
                alt=""
              />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/15.jpg"
                alt=""
              />
            </Carousel.Item> */}
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/16.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/17.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/18.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/19.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/20.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/21.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/22.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/23.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/24.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/25.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/26.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/27.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/28.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/29.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/30.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/31.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/32.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/33.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/34.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/35.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/36.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/37.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/38.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/39.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/40.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/41.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/42.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/portraits/43.jpg"
                alt=""
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Portraits;
