import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

import Header from "./Header";
import Footer from "./Footer";

class Events extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="view">
          <Carousel>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/1.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/2.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/3.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/4.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/5.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/6.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/7.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/8.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/9.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/10.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/11.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/12.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/13.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/14.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/15.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/16.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/17.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/18.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/19.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/20.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/21.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/22.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/23.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/24.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/25.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block"
                src="../img/events/26.jpg"
                alt=""
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Events;
