import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="home-container">
          <div className="home">
            <div className="tab-top">
              <div className="portraits">
                <Link
                  to='/portraits'
                  style={{textDecoration: 'none'}}
                >
                  <h1> PORTRAITS </h1>
                </Link>
              </div>
            </div>

            <div className="tab-body">
              <div className="movie-posters">
                <Link
                  to='/movieposters'
                  style={{textDecoration: 'none'}}
                >
                  <h1> MOVIE POSTERS </h1>
                </Link>
              </div>
            </div>

            <div className="tab-body">
              <div className="events">
                <Link
                  to='/events'
                  style={{textDecoration: 'none'}}
                >
                  <h1> EVENTS </h1>
                </Link>
              </div>
            </div>

            <div className="tab-body">
              <div className="real-estate">
                <Link
                  to='/realestate'
                  style={{textDecoration: 'none'}}
                >
                  <h1> REAL ESTATE </h1>
                </Link>
              </div>
            </div>

            <div className="tab-body">
              <div className="concerts">
                <Link
                  to='/concerts'
                  style={{textDecoration: 'none'}}
                >
                  <h1> CONCERTS </h1>
                </Link>
              </div>
            </div>

            <div className="tab-end">
              <div className="video">
                <Link
                  to='/video'
                  style={{textDecoration: 'none'}}
                >
                  <h1> VIDEO WORK </h1>
                </Link>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Home;
