import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";

import Header from "./Header";
import Footer from "./Footer";

class Posters extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="view">
          <Carousel>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/1.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/2.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/3.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/4.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/5.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/6.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/7.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/8.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/9.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/10.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/11.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/12.jpg"
                alt=""
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="img-fluid rounded mx-auto d-block rounded mx-auto d-block"
                src="../img/movie-posters/13.jpg"
                alt=""
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Posters;
