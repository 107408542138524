import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p>
          IMAGES & VIDEO © 2021 Roger Chavez
          <br />
          <a href="mailto:Rogerjchavez77@gmail.com">ROGERJCHAVEZ77@GMAIL.COM</a>
          |
          <a href="tel:630-779-8699" className="phone">
            630.779.8699
          </a>
        </p>
      </div>
    );
  }
}

export default Footer;
